import React from 'react';
import styled from 'styled-components';
import Layout from '../components/layout';

import VendorPartnerLogos from '../components/vendor-partner-logos';

// styled components
import Title from '../components/styles/title';

// images
import ClipboardCaesar from '../images/clipboard-caesar.svg';

const Padding = styled.div`
  padding: 30px;

  .CompleteCaesar {
    display: flex;
    justify-content: center;
  }

  img {
    padding: 40px;
  }
`;

export default () => (
  <Layout>
    <Padding>
      <Title>Thank you, Thank you!</Title>
      <div className="CompleteCaesar">
        <img src={ClipboardCaesar} alt="Clipboard Caesar" />
      </div>
    </Padding>
    <VendorPartnerLogos />
  </Layout>
);
